/*globals fetch, process */
import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import "../styles/styles.scss"
import { defaultYear, defaultSeasonType, defaultWeek } from '../util/season'
import { withRouter } from 'react-router-dom'
import cc from 'classcat'

import TeamCard from '../components/TeamCard'
import Crown from '../components/Crown'
import throttle from '../util/throttle'

// for now, there's only one league
// eslint-ignore-line no-typos
const leagueId = "-KzPdROlkcWZDUsd47av";

let J = (o) => JSON.parse(JSON.stringify(o))

// there can be an idea of a current season & week in real life
// there can be an idea of a user-selected season & week
// leagues/${leagueId}/users/${user.uid}/season

/**
 * @type {Object}
 * @property {boolean} browser
 */
let _process = typeof process !== 'undefined' ? process : { browser: true }

class Season extends React.Component {

  _isMounted = false

  constructor (props) {
    console.log('CONSTRUCTOR')
    super(props)
    this.state = this.updateStateBasedOnProps({onlyGetUpdatedState: true}, {
      selectedTab: 'picks',
      isLoading: true,
      isUpdatingScores: false,
      isUpdatingTotals: false,
      canUpdateScores: true,
      timeLastUpdatedScores: 0,
      // following two numbers need to be 10x
      minTimeBetweenUpdateScores: 60000,
      updateScoresInterval: 60000 + Math.ceil(Math.random() * 100),
      gamesScale: {},
    });
    _process.browser && console.log('Season constructor', J(this.state || {}), J(this.props || {}));
    this.addLeagueListener = this.addLeagueListener.bind(this)
    this.addWeekListener = this.addWeekListener.bind(this)
    this.onSeasonNavigation = this.onSeasonNavigation.bind(this)
    this.leagueUserPicksForThisWeek = this.leagueUserPicksForThisWeek.bind(this)
    this.lockPicks = this.lockPicks.bind(this)
    this.lockPicksForPlayoffWeek = this.lockPicksForPlayoffWeek.bind(this)
    this.toggleTeamPick = this.toggleTeamPick.bind(this)
    this.onTabClick = this.onTabClick.bind(this)
    this.updateScores = this.updateScores.bind(this)
    this.resizeListener = throttle(this.resizeListener.bind(this), 100)
  }

  componentDidMount () {
    console.log('componentDidMount')
    this._isMounted = true
    if (_process.browser) {
      setTimeout(this.resizeListener, 100)
      window.addEventListener('resize', this.resizeListener)
      this.updateScoresTimer = setInterval(this.updateScores, this.state.updateScoresInterval)
    }
    this.addLeagueListener()
    this.addWeekListener()
  }

  resizeListener () {
    let gamesScale = {}
    let bodyWidth = _process.browser ? document.body.offsetWidth : 1200
    let containerPadding = 40
    let gamesWidth = 770
    if (bodyWidth > 600 && bodyWidth < 810) {
      // we need to scale down the games list
      gamesScale = { transform: `scale(${(bodyWidth - containerPadding) / gamesWidth})` }
    }
    if (this._isMounted && gamesScale !== this.state.gamesScale) {
      this.setState({ gamesScale })
    }
  }

  static getDeepObjectProperty (objectAndPropArray) {
    return objectAndPropArray.reduce((ret, curr) => {
      return ret[curr] || false
    })
  }

  // need to move most of the logic elsewhere, doesn't belong ONLY in db listener - also needs to update when getting new props
  updateStateBasedOnProps (options, initialState) {
    // console.log('this.updateStateBasedOnProps initialState', initialState)
    let _this = this
    let state = this.state || initialState
    let props = this.props
    let params = this.props.match ? this.props.match.params : {}
    let league = state.league || props.league || {}
    let leagueUsers = league.users
    let week = state.week || props.week || {}
    let picks = props.user && this.leagueUserPicksForThisWeek(props.user.uid, league)
    // #sortedGames
    let sortedGames = Object.keys(JSON.parse(JSON.stringify(week)))
      .filter(i => week[i].isoTime)
      .sort((a, b) => {
        /**
         * @type {{gameId: string, isoTime: string}}
         */
        let game1 = week[a]
        let game2 = week[b]
        return game1.isoTime !== game2.isoTime
          ? (game1.isoTime < game2.isoTime ? -1 : 1)
          : (game1.gameId < game2.gameId ? -1 : 1)
      }).map(key => week[key])
    // #seasonStandings
    let seasonStandings = Object.keys(leagueUsers || {})
      .map(userId => {
        let leagueUser = leagueUsers[userId || (props.user || {}).uid] || {}
        let leagueUserSeason = Season.getDeepObjectProperty([leagueUser, 'season', params.year, params.seasonType])
        return {
          userId: userId,
          displayName: leagueUsers[userId].displayName,
          gamePoints: leagueUserSeason.gamePoints,
          weekPoints: leagueUserSeason.weekPoints,
        }
      })
      .sort((a, b) => {
        return a.weekPoints > b.weekPoints ? -1 : (a.weekPoints === b.weekPoints ? (a.gamePoints > b.gamePoints ? -1 : 1) : 1)
      })
    // #standings
    let standings = Object.keys(leagueUsers || {})
      .map(userId => {
        let leagueUserPicksForThisWeek = this.leagueUserPicksForThisWeek(userId, league)
        return {
          userId: userId,
          displayName: leagueUsers[userId].displayName,
          picks: leagueUserPicksForThisWeek || {},
          totalYards: (leagueUserPicksForThisWeek || {}).totalYards,
          isTiedWith: [],
          isBetterThan: {},
          points: Object.keys(leagueUserPicksForThisWeek || {}).reduce((points, gameId) => {
            /**
             * @type {{gameId, homeTeam, visitorTeam, winner, phase}}
             */
            let game = sortedGames.find(game => { return '' + game.gameId === '' + gameId })
            // console.log('userId', userId, 'gameId', gameId, 'game', game, game && (game.homeTeam.score + ', ' + game.visitorTeam.score))
            if (!game) return points
            game.winner = '' + (game.homeTeam.score !== game.visitorTeam.score ? Number(game.homeTeam.score > game.visitorTeam.score) : '')
            return points + (
              /final/i.test(game.phase) &&
              leagueUserPicksForThisWeek[gameId] === game.winner
                ? 1
                : 0
            )
          }, 0),
        }
      })
      .sort((userA, userB) => {
        if (userA.points === userB.points) {
          // debugger
          // (reverse operates in-place, so let's use an old-fashioned loop instead)
          let game
          let userAPick
          let userBPick
          let gameSpread
          let userASpread = 0
          let userBSpread = 0
          let i
          for (i = sortedGames.length - 1; i >= 0; i--) {
            game = sortedGames[i]
            userAPick = userA.picks[game.gameId]
            userBPick = userB.picks[game.gameId]
            if (userAPick !== userBPick) {
              gameSpread = Math.abs(game.homeTeam.score - game.visitorTeam.score)
              if (userAPick !== game.winner) {
                userASpread = Math.max(userASpread, gameSpread)
              } else {
                userBSpread = Math.max(userBSpread, gameSpread)
              }
            }
          }
          if (userASpread !== userBSpread) {
            // console.log('>>>>> ', userA.displayName, userA.isWinner ? 'winner' : '', userB.displayName, userB.isWinner ? 'winner' : '')
            if (userASpread < userBSpread) {
              userA.isBetterThan[userB.userId] = 1
              return -1
            } else {
              userB.isBetterThan[userA.userId] = 1
              return 1
            }
          }
          // if we got this far, then it is an actual tie.
          // tied with each other, and also tied with anyone else either is already tied with
          userA.isTiedWith = userB.isTiedWith = [userA.userId, userB.userId].concat(userA.isTiedWith, userB.isTiedWith)
        }
        return userA.points > userB.points ? -1 : 1
      })

    let leader = standings[0]

    // won by points
    if (standings.length === 1 || (standings.length > 1 && leader.points > standings[1].points)) {
      leader.isWinner = true
    }

    // check for win by tiebreaker.
    if (leader && leader.isBetterThan[standings[1].userId]) {
      leader.isWinner = true
      leader.isTiedWith = []
    }

    // check for ties by association
    leader && leader.isTiedWith.length && standings.some(user => {
      user.isWinner = user.isTiedWith.indexOf(leader.userId) !== -1
      return user.points !== leader.points;
    })

    let profile = props.user ? ((leagueUsers || {})[props.user.uid] || {}).profile : {}

    // check to see if allScoresAreFinal was false before, but is now true .. and if so, then update season points.
    if (options && options.updateStandings) {
      // console.log('updating standings')
      // update the db with game points, if we haven't already.
      let leadersPicksForThisWeek = leader && leader.userId && this.leagueUserPicksForThisWeek(leader.userId, league)
      _process.browser && console.log('leadersPicksForThisWeek, week', week, 'leadersPicksForThisWeek', J(leadersPicksForThisWeek || {}), leader && leader.userId)
      if (!this.isUpdatingTotals && leadersPicksForThisWeek && !('isWinner' in leadersPicksForThisWeek)) {
        // console.log('updating user points')
        this.isUpdatingTotals = true
        let numUpdatesPending = 4 * standings.length
        standings.forEach(userWeeklyStanding => {
          let isWinner = userWeeklyStanding.isWinner
          let gamePoints = userWeeklyStanding.points
          let weekPoints = isWinner ? 1 : 0
          let userSeasonDb = firebase.database().ref(
            '/leagues/' + leagueId +
            '/users/' + userWeeklyStanding.userId +
            '/season/' + params.year +
            '/' + params.seasonType
          )
          let userWeekDb = firebase.database().ref(
            '/leagues/' + leagueId +
            '/users/' + userWeeklyStanding.userId +
            '/season/' + params.year +
            '/' + params.seasonType +
            '/week/' + params.week
          )
          userSeasonDb.once('value', snapshot => {
            let dbNode = snapshot.val()
            userWeekDb.child('isWinner').set(isWinner).then(resolvePendingUpdate)
            userWeekDb.child('gamePoints').set(gamePoints).then(resolvePendingUpdate)
            userSeasonDb.child('weekPoints').set((dbNode.weekPoints || 0) + weekPoints).then(resolvePendingUpdate)
            userSeasonDb.child('gamePoints').set((dbNode.gamePoints || 0) + gamePoints).then(resolvePendingUpdate)
          })
        })
        function resolvePendingUpdate () {
          if (!--numUpdatesPending) {
            // console.log('all updates resolved')
            firebase.database().ref('leagues/' + leagueId).on('value', snap => {
              let league = snap.val()
              if (_this._isMounted) {
                _this.setState({
                  league,
                })
              }
            })
          }
        }
      }
    }

    let _state = {
      league,
      picks,
      week,
      sortedGames,
      seasonStandings,
      standings,
      profile,
      timeLastUpdatedScores: sortedGames[0] ? sortedGames[0].timeLastUpdatedScores : 1,
    }

    if (!options || !options.onlyGetUpdatedState) {
      this._isMounted && this.setState(_state)
    } else {
      return Object.assign(state, _state)
    }

  }

  componentDidUpdate (prevProps, prevState) {
    // make sure we're only acting upon actual changes.
    if (_process.browser) {
      console.groupCollapsed('componentDidUpdate')
      console.log('prevProps', ((prevProps || {}) || {}))
      console.log('props', (this.props || {}))
      console.log('prevState', ((prevState || {}) || {}))
      console.log('state', (this.state || {}))
      console.groupEnd()
    }
    let allScoresWereAlreadyFinal = isAllFinal(prevProps.week || {}) || isAllFinal(prevState.week || {});
    let allScoresAreFinal = isAllFinal(this.props.week || {}) || isAllFinal(this.state.week || {});

    function isAllFinal (games) {
      // _process.browser && console.log('isAllFinal ?', Object.keys(games).length && !Object.keys(games).some(i => isNaN(+i) ? false : !/final/i.test(games[i].phase)))
      return Object.keys(games).length && !Object.keys(games).some(i => isNaN(+i) ? false : !/final/i.test(games[i].phase))
    }

    if (this.state.shouldShowRelevantGamesOnly !== prevState.shouldShowRelevantGamesOnly) {
      this.state.ReactGA && this.state.ReactGA._event({
        category: 'picks',
        action: 'shouldShowRelevantGamesOnly',
        label: '' + this.state.shouldShowRelevantGamesOnly,
      })
    }

    if (prevProps.match.url !== this.props.match.url) {
      // stop listening for changes to the previous week
      firebase.database().ref('schedules/season/' + prevProps.match.params.year + '/' + prevProps.match.params.seasonType + '/week/' + prevProps.match.params.week).off();
      // listen for changes to the new week
      this.addWeekListener()
    }

    if (JSON.stringify(this.props) !== JSON.stringify(prevProps) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      _process.browser && console.log('componentDidUpdate, about to update state based on props', 'allScoresAreFinal', allScoresAreFinal, 'allScoresWereAlreadyFinal', allScoresWereAlreadyFinal)
      // check to see if allScoresAreFinal was false before, but is now true .. and if so, then update season points.
      this.updateStateBasedOnProps({updateStandings: allScoresAreFinal && !allScoresWereAlreadyFinal})
    } else {
      _process.browser && console.log('didUpdate - no changes though.')
    }
  }

  addLeagueListener () {
    _process.browser && console.log('addLeagueListener')
    firebase.database().ref('leagues/' + leagueId).on('value', snap => {
      let league = snap.val()
      this._isMounted && this.setState({
        isLoading: false,
        league,
      }, () => {
        _process.browser && console.log('====== on league', JSON.stringify(league).substring(0, 200))
        this.updateStateBasedOnProps()
      })
    }, (error) => {
      _process.browser && console.error(error)
    })
  }

  addWeekListener() {
    let params = this.props.match ? this.props.match.params : {}
    _process.browser && console.log('addWeekListener')
    let weekDb = firebase.database().ref('schedules/season/' + params.year + '/' + params.seasonType + '/week/' + params.week);
    weekDb.on('value', snap => {
      let week = snap.val()
      this._isMounted && this.setState({
        isLoading: false,
        week,
      }, () => {
        _process.browser && console.log('====== on schedule', JSON.stringify(week).substring(0, 200))
        this.updateStateBasedOnProps()
      })
    }, (error) => {
      _process.browser && console.error(error)
    })
  }

  removeDbListener () {
    // firebase.database().ref('leagues').off()
    // firebase.database().ref('schedule').off()
  }

  onSeasonNavigation (event) {
    let params = this.props.match ? this.props.match.params : {}
    let target = event.target
    let name = target.name
    let value = target.value
    params[name] = value
    let isCurrentYear = params.year === defaultYear
    if (name === 'year') {
      params.seasonType = isCurrentYear ? defaultSeasonType : 'PRE'
      params.week = isCurrentYear ? defaultWeek : params.seasonType === 'PRE' ? '0' : '1'
    }
    if (name === 'seasonType') {
      params.week = isCurrentYear && value === defaultSeasonType ? defaultWeek : value === 'PRE' ? '0' : '1'
    }
    // console.log('onchange', params)
    // this.state.ReactGA._event({
    //   category: 'navigation',
    //   action: 'change',
    //   label: [params.year, params.seasonType, params.week].join('/'),
    // })
    delete this.state.week
    this.props.history.push(`/season/${params.year}/${params.seasonType}/${params.week}`)
  }

  onTabClick (tab) {
    if (tab !== this.state.selectedTab) {
      this.setState({
        selectedTab: tab,
      })
    }
  }

  getPicksRef () {
    let params = this.props.match ? this.props.match.params : {}
    return firebase.database().ref(
      '/leagues/' + leagueId +
      '/users/' + this.props.user.uid +
      '/season/' + params.year +
      '/' + params.seasonType +
      '/week/' + params.week
    )
  }

  leagueUserPicksForThisWeek (userId, league) {
    let params = this.props.match ? this.props.match.params : {}
    let leagueUsers = (league || {}).users || {}
    let leagueUser = leagueUsers[userId || (this.props.user || {}).uid] || {}
    // _process.browser && console.log('getting leagueUserPicksForThisWeek, week', userId, this.props.match)
    return Season.getDeepObjectProperty([leagueUser, 'season', params.year, params.seasonType, 'week', params.week])
  }

  lockPicks () {
    let _this = this;
    let params = this.props.match ? this.props.match.params : {}
    if (!this.props.user || this.state.isLoading) return
    window.scrollTo(0, 0)
    this.state.ReactGA && this.state.ReactGA._event({
      category: 'picks',
      action: 'lockPicks',
      label: [params.year, params.seasonType, params.week].join('/'),
    })
    this.getPicksRef().child('isLocked').set(true).then(() => {
      _this.setState({
        isLoading: false,
        shouldShowLockPicksDialog: false,
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    })
  }

  lockPicksForPlayoffWeek (playoffWeekNumber) {
    let _this = this;
    let params = this.props.match ? this.props.match.params : {}
    if (!this.props.user || this.state.isLoading) return
    window.scrollTo(0, 0)
    this.state.ReactGA && this.state.ReactGA._event({
      category: 'picks',
      action: 'lockPicksForPlayoffWeek',
      label: [params.year, params.seasonType, params.week, playoffWeekNumber].join('/'),
    })
    this.getPicksRef().child('isLockedPlayoffsWeek').set(true).then(() => {
      _this.setState({
        isLoading: false,
        shouldShowLockPicksDialog: false,
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    })
  }

  toggleTeamPick (gameId, teamIndex) {
    let params = this.props.match ? this.props.match.params : {}
    if (
      (this.state.picks && this.state.picks.isLocked)
      || !this.props.user
      || this.state.isLoading
      || (this.state.picks && this.state.picks[gameId] === '' + teamIndex)
    ) {
      _process.browser && console.log('toggleTeamPick - bailing early', this.state.picks && this.state.picks.isLocked && '- picks are locked')
      return
    }
    this.setState({
      isLoading: true
    })
    // this.picks['' + gameId] = teamIndex
    _process.browser && console.log('toggleTeamPick', gameId, teamIndex)
    this.state.ReactGA && this.state.ReactGA._event({
      category: 'picks',
      action: 'toggleTeamPick',
      label: [params.year, params.seasonType, params.week].join('/') + '?gameId=' + gameId + '&teamIndex=' + teamIndex,
    })
    this.getPicksRef().child('' + gameId).set('' + teamIndex).then(() => {
      this.setState({
        isLoading: false
      })
    })
  }

  updateScores () {
    if (!this._isMounted) return;
    let _this = this
    let params = this.props.match ? this.props.match.params : {}
    let now = Date.now()
    let timeLastUpdatedScores = this.state.timeLastUpdatedScores
    // let gameIdsThatNeedTotalYards = []
    // let lastGameId = this.games[this.games.length - 1].gameId

    // are any games in progress?
    let firstSundayInNovember = new Date(params.year, 10, 1)
    while (firstSundayInNovember.getDay() > 0) {
      firstSundayInNovember.setDate(firstSundayInNovember.getDate() + (24 * 60 * 60))
    }
    const ESTTimeOffset = 60 * 1000 * (now > +firstSundayInNovember ? -300 : -240)
    const userTimezoneOffset = 60 * 1000 * firstSundayInNovember.getTimezoneOffset()

    let isGameInProgress = this.state.sortedGames.some(game => {
      let gameDate = new Date(game.isoTime)
      _process.browser && console.log(game.visitorTeam.nick, game.homeTeam.nick, +gameDate, ESTTimeOffset, userTimezoneOffset, game.phase)
      return (now > (gameDate + ESTTimeOffset + userTimezoneOffset)) && !/final/i.test(game.phase)
    })

    _process.browser && console.log('is there a game in progress?', isGameInProgress)

    if (!isGameInProgress || !timeLastUpdatedScores || now - timeLastUpdatedScores < this.state.minTimeBetweenUpdateScores) {
      _process.browser && console.log('aborting update scores:', now - timeLastUpdatedScores < this.state.minTimeBetweenUpdateScores ? 'too soon' : '', timeLastUpdatedScores, isGameInProgress ? '' : 'no game in progress')
      return
    }

    _process.browser && console.log('ABOUT TO UPDATE SCORES')

    // this.state.ReactGA._event({
    //   category: 'scores',
    //   action: 'updateScores',
    //   label: [params.year, params.seasonType, params.week].join('/'),
    // })

    fetch('https://feeds.nfl.com/feeds-rs/scores.json')
    .then(response => {
      return response.json()
    })
    .then(json => {
      let numGames = json.gameScores.length
      let numGamesProcessed = 0
      let weekNumber = json.week > 17 ? 1 : json.week
      let weekDb = firebase.database().ref(`/schedules/season/${json.season}/${json.seasonType}/week/${weekNumber}`)

      json.gameScores.forEach(game => {
        // find the matching game in the db and set the score
        // CANNOT RELY ON `gameId` !!! ... if a game's day or time changes, the gameId will also change.
        let homeTeamNick = game.gameSchedule.homeNickname
        let visitorTeamNick = game.gameSchedule.visitorNickname
        // have to check both home team and visitor team during the postseason.
        let gameFromSchedule = this.state.sortedGames.find(game => {
          return game.homeTeam.nick === homeTeamNick &&
            game.visitorTeam.nick === visitorTeamNick
        })
        // use the existing gameId from when the schedule was first created, or else picks will become misaligned.
        let gameId = gameFromSchedule.gameId
        _process.browser && console.log(homeTeamNick, 'gameFromSchedule', gameFromSchedule, game)
        // fix the time if a game's time has changed
        if (gameFromSchedule && game.gameSchedule.isoTime !== gameFromSchedule.isoTime) {
          _process.browser && console.log('game date/time mismatch, updating game time', 'gameFromSchedule', gameFromSchedule, 'game from scores feed', game)
          weekDb
            .orderByChild('gameId')
            .equalTo(gameId)
            .once('value', snapshot => {
              let gameFromDb = snapshot.val()
              if (!gameFromDb || Object.keys(gameFromDb).length === 0) {
                _process.browser && console.log('something wrong with the game from db', gameFromDb, 'for id', gameId);
                return;
              }
              let key = Object.keys(gameFromDb)[0]
              _process.browser && console.log('updating game time for game', key, gameId, JSON.stringify(gameFromDb[key]), JSON.stringify(game.gameSchedule))
              // we have to create a nearly identical updateObject, and can't just use the gameFromDb directly,
              // because firebase snapshot val() may 'optimize' objects with numeric keys as if they were arrays
              // and introduce nulls -- i.e., key = 1, snapshot val = [null, {game}]
              let updateObject = {}
              updateObject[key] = JSON.parse(JSON.stringify(gameFromDb[key]))
              // console.log('gameId', game.gameSchedule.gameId, 'key', key, 'snapshot val', snapshot.val())
              updateObject[key].gameDate = game.gameSchedule.gameDate
              updateObject[key].gameTimeEastern = game.gameSchedule.gameTimeEastern
              updateObject[key].gameTimeLocal = game.gameSchedule.gameTimeLocal
              updateObject[key].isoTime = game.gameSchedule.isoTime
              snapshot.ref.update(updateObject)
            })
        }

        if (
          game.score && game.score.homeTeamScore &&
          (
            game.score.phase !== gameFromSchedule.phase ||
            game.score.homeTeamScore.pointTotal !== gameFromSchedule.homeTeam.score ||
            game.score.visitorTeamScore.pointTotal !== gameFromSchedule.visitorTeam.score
          )
        ) {
          _process.browser && console.log('updating game scores', gameFromSchedule.visitorTeam.nick, '@', gameFromSchedule.homeTeam.nick)
          // console.log('updating game scores', gameFromSchedule.visitorTeam.nick, '@', gameFromSchedule.homeTeam.nick, game.score.visitorTeam.pointTotal, '-', game.score.homeTeam.pointTotal)
          weekDb
            .orderByChild('gameId')
            .equalTo(gameId)
            .once('value', snapshot => {
              let gameFromDb = snapshot.val()
              let key = Object.keys(gameFromDb)[0]
              // we have to create a nearly identical updateObject, and can't just use the gameFromDb directly,
              // because firebase snapshot val() may 'optimize' objects with numeric keys as if they were arrays
              // and introduce nulls -- i.e., key = 1, snapshot val = [null, {game}]
              let updateObject = {}
              updateObject[key] = JSON.parse(JSON.stringify(gameFromDb[key]))
              // console.log('gameId', game.gameSchedule.gameId, 'key', key, 'snapshot val', snapshot.val())
              updateObject[key].homeTeam.score = game.score.homeTeamScore.pointTotal
              updateObject[key].visitorTeam.score = game.score.visitorTeamScore.pointTotal
              updateObject[key].phase = game.score.phase
              snapshot.ref.update(updateObject)
              // if the score is final, and we don't already have the totalYards, flag this game as needing to get the total yards
              // _process.browser && console.log('totalYards ?', gameId, game.score.phase, gameFromDb.totalYards, 'is final?', /final/i.test(game.score.phase), !gameFromDb.totalYards)
              // we quit caring about total yards, besides, the fetch command didn't work.
              /*
              if (gameId === lastGameId && /final/i.test(game.score.phase) && !gameFromDb.totalYards && this.needsActualTotalYards) {
                gameIdsThatNeedTotalYards.push(gameId)
                // fetch('https://corsify.appspot.com/http://www.nfl.com/liveupdate/game-center/' + gameId + '/' + gameId + '_gtd.json')
              }
              */
              if(_this._isMounted && ++numGamesProcessed === numGames) {
                _this.setState({
                  isUpdatingScores: false,
                  canUpdateScores: Date.now() - now > this.state.minTimeBetweenUpdateScores,
                })
              }
            })
        } else {
          if(_this._isMounted && ++numGamesProcessed === numGames) {
            _this.setState({
              isUpdatingScores: false,
              canUpdateScores: Date.now() - now > this.state.minTimeBetweenUpdateScores,
            })
          }
        }
      })

      // let _start = Date.now()
      // _process.browser && console.log('start waiting for all games to have evaluation of whether they need totalYards', new Date())
      //<editor-fold defaultstate="collapsed" desc="update the total yards .. except no, we don't care about total yards anymore. besides, the apify.com stuff doesn't work well for total yards :(">
      /** /
      function updateYards () {
        if (numGamesProcessed !== numGames) {
          setTimeout(updateYards, 300)
        }
        console.log('done waiting .. ', Date.now() - _start, new Date())
        if (gameIdsThatNeedTotalYards.length) {
          // process the games that need total yards
          fetch('https://api.apify.com/v2/acts/dbh~game-stats/runs', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({gameIds: gameIdsThatNeedTotalYards}),
          })
            .then(r => {
              return r.json()
            })
            .then(json => {
              console.log('apify act response:', json)
              // give it a little time to make sure it's there.
              setTimeout(() => {
                fetch(`https://api.apify.com/v2/key-value-stores/${json.data.defaultKeyValueStoreId}/records/OUTPUT`)
                  .then(r => {
                    return r.json()
                  })
                  .then(json => {
                    console.log('apify key value store response:', json)
                    if (json.error) {
                      console.error(json.error.message)
                      return
                    }
                    if (typeof json === 'string') {
                      json = JSON.parse(json)
                    }
                    gameIdsThatNeedTotalYards.forEach(gameId => {
                      weekDb
                        .orderByChild('gameId')
                        .equalTo(gameId)
                        .once('value', snapshot => {
                          let gameFromDb = snapshot.val()
                          let key = Object.keys(gameFromDb)[0]
                          // we have to create a nearly identical updateObject, and can't just use the gameFromDb directly,
                          // because firebase snapshot val() may 'optimize' objects with numeric keys as if they were arrays
                          // and introduce nulls -- i.e., key = 1, snapshot val = [null, {game}]
                          let updateObject = {}
                          updateObject[key] = gameFromDb[key]
                          // let stats = json[gameId]
                          updateObject[key].totalYards = json['' + gameId].home.stats.team.totyds + json['' + gameId].away.stats.team.totyds
                          snapshot.ref.update(updateObject)
                        })
                    })
                    _this.isUpdatingScores = false
                    _this.canUpdateScores = Date.now() - _this.timeLastUpdatedScores > _this.minTimeBetweenUpdateScores
                    console.log('can update scores?', _this.canUpdateScores, Date.now() - _this.timeLastUpdatedScores, _this.minTimeBetweenUpdateScores)
                    if (!_this.canUpdateScores) {
                      setTimeout(() => {
                        _this.canUpdateScores = true
                        console.log('can update scores?', _this.canUpdateScores)
                      }, _this.minTimeBetweenUpdateScores - Date.now() - _this.timeLastUpdatedScores)
                    }
                  })
              }, 5000)
            })
        } else {
          _this.isUpdatingScores = false
          _this.canUpdateScores = Date.now() - _this.timeLastUpdatedScores > _this.minTimeBetweenUpdateScores
          if (!_this.canUpdateScores) {
            setTimeout(() => {
              _this.canUpdateScores = true
            }, _this.minTimeBetweenUpdateScores - Date.now() - _this.timeLastUpdatedScores)
          }
        }
      }
      updateYards()
      /**/
      //</editor-fold>
      _process.browser && console.log('parsed json', json.gameScores)

      weekDb.child('0/timeLastUpdatedScores').set(now)

    }).catch(ex => {
      // based on how `fetch` works, we only get here if the user lost internet connection.
      // _this.setState({
      //   isUpdatingScores: false,
      //   canUpdateScores: true,
      // })
      _process.browser && console.log('network failed', ex)
    })
  }

  componentWillUnmount () {
    console.log('componentWillUnmount')
    clearInterval(this.updateScoresTimer)
    window.removeEventListener('resize', this.resizeListener)
    // firebase.database().ref().off()
  }

  render () {
    let _this = this;
    let { league, picks, seasonType, selectedTab, sortedGames, seasonStandings, standings, profile } = this.state
    let user = this.props.user
    let params = this.props.match ? this.props.match.params : {}
    let years = []
    for (let yearsIndex = +defaultYear; yearsIndex > 2016; yearsIndex--) {
      years.push(yearsIndex)
    }
    let week = this.state.week || this.props.week || {}
    let weeks = []
    let maxWeek = +((week || {}).numWeeks
      || params.seasonType === 'REG'
        ? 17
        : params.seasonType === 'PRE' ? 4 : 1)
    for (let i = 1; i <= maxWeek; i++) {
      weeks.push(i)
    }
    // let isActualWeek = ![
    //   params.year === defaultYear,
    //   params.seasonType === defaultSeasonType,
    //   params.week === defaultWeek,
    // ].some(bool => !bool)

    let leagueUsers = (league || {}).users
    let leagueUserPicks = {} //{numLeagueUsersWithLockedPicks: 0}

    // adjustments for POST season
    let isPlayoffs = seasonType === 'POST'
    let playoffWeekNumber = 1
    // let playoffGamesPerWeek = [4, 4, 2]

    if (isPlayoffs) {
      // todo: set isLocked based on real world date and picks.isLocked1, isLocked2, isLocked3
      picks.isLocked = true
    }

    // no sortedGames means no 'week' from the SCHEDULE part of the db.
    if (!sortedGames.length) {
      console.warn('Season :: no sorted games')
      return <div className={'season'}>
        <div className={'loading'}>
          Loading...
        </div>
      </div>
    }

    let now = Date.now()
    let firstSundayInNovember = new Date(params.year, 10, 1)
    while (firstSundayInNovember.getDay() > 0) {
      firstSundayInNovember.setDate(firstSundayInNovember.getDate() + (24 * 60 * 60))
    }
    const ESTTimeOffset = 60 * 1000 * (now > +firstSundayInNovember ? -300 : -240)
    const userTimezoneOffset = 60 * 1000 * firstSundayInNovember.getTimezoneOffset()
    const noonThursday = new Date(sortedGames[0].isoTime)
    while (noonThursday.getDay() !== 4) {
      noonThursday.setDate(noonThursday - (24 * 60 * 60))
    }
    noonThursday.setHours(12, 0, 0, 0)
    let isPastCutoff = now > (+noonThursday + ESTTimeOffset + userTimezoneOffset)
    _process.browser && console.log('noonThursday', noonThursday, 'now', now, 'ESTTimeOffset', ESTTimeOffset, 'userTimezoneOffset', userTimezoneOffset);


    // if the current users' picks are locked, show what everyone's picks are.
    // the following logic creates arrays of user objects keyed by gameId
    if (isPastCutoff || (picks || {}).isLocked) {
      Object.keys(leagueUsers || {}).forEach(userId => {
        let leagueUserPicksForThisWeek = this.leagueUserPicksForThisWeek(userId, league)
        // _process.browser && console.log('leagueUserPicksForThisWeek', leagueUserPicksForThisWeek)
        // leagueUserPicks.numLeagueUsersWithLockedPicks += (leagueUserPicksForThisWeek || {}).isLocked ? 1 : 0
        if (leagueUserPicksForThisWeek) {
          Object.keys(leagueUserPicksForThisWeek || {}).forEach(gameId => {
            // people who picked the visitor team [], people who picked the home team [].
            if (isNaN(+gameId)) return
            leagueUserPicks[gameId] = leagueUserPicks[gameId] || [[], []]
            leagueUserPicks[gameId][+leagueUserPicksForThisWeek[gameId]].push({
              userId: userId,
              displayName: leagueUsers[userId].displayName,
              isCurrentUser: userId === (_this.props.user || {}).uid,
              isLocked: leagueUserPicksForThisWeek.isLocked,
            })
          })
        }
      })
    }

    let allScoresAreFinal = !sortedGames.some(game => {
      return !/final/i.test(game.phase)
    });

    // if someone forgot to make picks, don't even show their name in the standings.
    if (standings && standings.length) {
      standings = standings.filter(_user => Object.keys(_user.picks).length)
    }
    // console.log('type of standings', typeof standings, Array.isArray(standings), standings.length);

    if (_process.browser) {
      console.groupCollapsed('render')
      console.log(Object.keys(picks || {}).length, Object.keys(week).length)
      console.log('user', user)
      console.log('league', league)
      console.log('leagueUserPicks', leagueUserPicks)
      console.log('picks', picks)
      console.log('week', week)
      console.log('sortedGames', sortedGames)
      console.log('standings', standings)
      console.log('profile', profile)
      console.groupEnd()
    }

    return <div className={'season'}>
      <nav>
        <select
          name="year"
          value={params.year}
          onChange={this.onSeasonNavigation}
        >
          {years.map(key =>
            <option key={key}>
              {key}
            </option>
          )}
        </select>
        <select
          name="seasonType"
          value={params.seasonType}
          onChange={this.onSeasonNavigation}
        >
          {['PRE', 'REG', 'POST'].map(key =>
            <option key={key}>
              {key}
            </option>
          )}
        </select>
        <select
          name="week"
          value={params.week}
          onChange={this.onSeasonNavigation}
        >
          {weeks.map(key =>
            <option key={key}>
              {key}
            </option>
          )}
        </select>
      </nav>

      {user &&
        <div
          className={cc({
            tabpanel: true,
            standings: true,
            open: selectedTab === 'standings',
          })}
          style={{margin: '0 auto 50px'}}
        >
          {/* Week Standings */}
          <table>
            <tbody>
            <tr>
              <th colSpan={3}>WEEK</th>
            </tr>
            {standings.map(_user =>
              <tr key={_user.userId}>
                <td>
                  {
                    _user.isWinner && allScoresAreFinal &&
                    <div className={'crown-container'}>
                      <Crown/>
                    </div>
                  }
                </td>
                <td>
                  {(_user.displayName || 'Anonymous')}
                </td>
                <td>
                  {_user.points}
                </td>
              </tr>
            )}
            </tbody>
          </table>
          {/* Season Standings */}
          <table style={{opacity: .6}}>
            <tbody>
            <tr>
              <th colSpan={3}>SEASON</th>
            </tr>
            {seasonStandings.map(user =>
              <tr key={user.userId}>
                <td>
                  {(user.displayName || 'Anonymous')}
                </td>
                <td valign="top">
                  {user.weekPoints || '0'}
                  <small className={'mobile-hidden'}>
                    {' '}
                    Week
                    {(user.weekPoints || 0) === 1 ? '' : 's'}
                  </small>
                </td>
                <td>
                  <small style={{color: '#aaa'}}>
                    <span className={'mobile-only'}>
                      / {' '}
                    </span>
                    {user.gamePoints || '0'}
                    <span className={'_mobile-hidden'}>
                      {' '}
                      Game
                      {(user.gamePoints || 0) === 1 ? '' : 's'}
                    </span>
                  </small>
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      }

      <div className={cc({tabpanel: true, open: selectedTab === 'picks', 'is-picks-locked': (picks || {}).isLocked})}>
        {
          user && (picks || {}).isLocked &&
          <div>
            <label className="switch">
              <span className={'switcher'}>
                <input
                  className="switch__input"
                  type="checkbox"
                  checked={!!this.state.shouldShowRelevantGamesOnly}
                  onChange={() => this.setState({shouldShowRelevantGamesOnly: !this.state.shouldShowRelevantGamesOnly})}
                />
                <span className="switch__slider"/>
              </span>
              <span className={'switch__label'}>
                Show only relevant games
              </span>
            </label>
            <br/>
            <br/>
            {
              (Date.now() === 1 && league.owner === user.uid) &&
              <div className={'update-scores-container'}>
                Do we need to update the scores?
                {' '}
                {/* todo: automate this instead */}
                <button
                  className={cc({
                    'update-scores-link': true,
                    'loading': !this.state.canUpdateScores,
                  })}
                  onClick={this.updateScores}
                >
                  Update Scores
                </button>
                <br/>
                <br/>
              </div>
            }
          </div>
        }

        {
          week &&
          <div className="games" style={this.state.gamesScale}>
            {sortedGames.map((game, gameIndex) => {
              // console.log(gameIndex, 'game', game)
              // let game = week[key]
              if (!game || !game.homeTeam) return undefined
              let winner = /final/i.test(game.phase) && game.homeTeam.score !== game.visitorTeam.score && (
                game.homeTeam.score > game.visitorTeam.score
                  ? 'home'
                  : 'visitor'
              )
              let isRelevant = !!Math.min(
                (leagueUserPicks[game.gameId] || [[], []])[0].length,
                (leagueUserPicks[game.gameId] || [[], []])[1].length
              );
              return (
                <div key={(game.visitorTeam || {}).nick + '@' + (game.homeTeam || {}).nick + ' - ' + game.isoTime}>
                  {(gameIndex === 0 || game.isoTime !== sortedGames[gameIndex - 1].isoTime) ? (() => {
                    let gameDate = new Date(game.gameDate)
                    let dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                    let monthNames = [
                      "January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December",
                    ]
                    let dayOfWeek = dayNames[gameDate.getDay()]
                    let timeOfDay = game.gameTimeEastern.split(':')
                    let amOrPm = Number(timeOfDay[0]) > 11 ? 'pm' : 'am'
                    timeOfDay[0] = Number(timeOfDay[0]) > 12 ? Number(timeOfDay[0]) - 12 : timeOfDay[0]
                    timeOfDay = timeOfDay.join(':').replace(/:00$/, '')
                    return (
                      <div
                        className={cc({
                          'date-header': 1,
                          'is-same-day': gameIndex && game.gameDate === sortedGames[gameIndex - 1].gameDate,
                        })}
                      >
                        <span className="date-header__day">
                          { dayOfWeek }
                          <span className="date-header__month-and-date" style={{textTransform: 'none', color: '#999', position: 'absolute', marginLeft: '12px'}}>
                            {' '}
                            { monthNames[gameDate.getMonth()] }
                            {' '}
                            { gameDate.getDate() }
                          </span>
                        </span>
                        <span className="date-header__time">
                          { timeOfDay }
                        </span>
                        <span className="date-header__am-pm" style={{fontSize: '75%', color: '#999'}}>
                          { amOrPm }
                        </span>
                      </div>
                    )
                  })() : ''}
                  <div
                    data-id={game.gameId}
                    className={cc({
                      'game': 1,
                      // if everybody picked the same team, the game is irrelevant
                      'is-game-irrelevant': this.state.shouldShowRelevantGamesOnly && !isRelevant,
                      'in-progress': game.phase !== 'PRE' && !/final/i.test(game.phase),
                    })}
                  >
                    <TeamCard
                      user={user}
                      team={game.visitorTeam}
                      isPicked={winner === 'visitor'}
                      onChange={_this.toggleTeamPick.bind(_this, game.gameId, '0')}
                      usersWhoPickedThisTeam={(leagueUserPicks[game.gameId] || [[], []])[0]}
                    />
                    {(isPastCutoff || (user && (picks || {}).isLocked)) &&
                      <span
                        className="score"
                        data-is-winner={winner === 'visitor'}
                        style={{display: (profile || {}).hideScores ? 'none' : ''}}
                      >
                        {(profile || {}).hideScores ? ' ' : game.visitorTeam.score}
                      </span>
                    }
                    {
                      !isPastCutoff && user && (!picks || !picks.isLocked) &&
                      <span>
                        <label className={'checkbox'}>
                          <input
                            className={'checkbox__input'}
                            type="checkbox"
                            disabled={picks && (picks.isLocked || picks.isPastCutoff)}
                            checked={!!(picks && picks[game.gameId] === '0')}
                            onChange={_this.toggleTeamPick.bind(_this, game.gameId, '0')}
                          />
                          <span className="checkmark"/>
                        </label>
                        <span style={{display: 'inline-block', width: '10px'}}/>
                        <label className={'checkbox'}>
                          <input
                            className={'checkbox__input'}
                            type="checkbox"
                            disabled={picks && (picks.isLocked || picks.isPastCutoff)}
                            checked={!!(picks && picks[game.gameId] === '1')}
                            onChange={_this.toggleTeamPick.bind(_this, game.gameId, '1')}
                          />
                          <span className="checkmark"/>
                        </label>
                      </span>
                    }
                    {(isPastCutoff || (user && (picks || {}).isLocked)) &&
                      <span
                        className="score"
                        data-is-winner={winner === 'home'}
                      >
                        {(profile || {}).hideScores ? ' ' : game.homeTeam.score}
                      </span>
                    }
                    <TeamCard
                      user={user}
                      team={game.homeTeam}
                      isPicked={winner === 'home'}
                      onChange={_this.toggleTeamPick.bind(_this, game.gameId, '1')}
                      usersWhoPickedThisTeam={(leagueUserPicks[game.gameId] || [[], []])[1]}
                    />
                  </div>
                  {isPlayoffs ? (() => {
                    let isLastGameOfWeek = gameIndex === sortedGames.length - 1 ||
                      (new Date(sortedGames[gameIndex + 1].gameDate)) - (new Date(game.gameDate)) > 5 * 24 * 60 * 60
                    // let isAlreadyLocked = user && picks && picks['isLockedPlayoffsWeek' + playoffWeekNumber]
                    if (isLastGameOfWeek) {
                      playoffWeekNumber++
                    }
                    return !isLastGameOfWeek ? '' : <div>
                      <label className="switch">
                        <span className={'switcher'}>
                          <input
                            className="switch__input"
                            type="checkbox"
                            checked={!!(picks || {})['isLockedPlayoffsWeek' + playoffWeekNumber]}
                            onChange={this.lockPicksForPlayoffWeek.bind(playoffWeekNumber)}
                          />
                          <span className="switch__slider"/>
                        </span>
                        <span className={'switch__label'}>
                          Lock Picks
                        </span>
                      </label>
                    </div>
                  })() : ''}
                </div>
              )
            })}
          </div>
        }

        <div
          className={cc({
            'dialog': 1,
            'dialog--open': this.state.shouldShowLockPicksDialog
          })}
        >
          <h3>
            Are you sure you want to lock in your picks?
          </h3>
          <button
            onClick={() => {
              this.setState({shouldShowLockPicksDialog: false})
            }}
            style={{marginRight: '20px'}}
          >
            No
          </button>
          <button
            onClick={this.lockPicks}
          >
            Yes
          </button>
        </div>

        {user && picks && !picks.isLocked && !picks.isPastCutoff && !isPlayoffs &&
          <div
            className={'actions'}
          >
            <button
              className={'button button--lock-picks'}
              disabled={
                Object.keys(picks || {}).length !== Object.keys(week).filter(el => { return !isNaN(el) }).length
              }
              onClick={() => {
                this.setState({shouldShowLockPicksDialog: true})
              }}
            >
              Lock Picks
            </button>
          </div>
        }

      </div>

    </div>
  }

}

export default withRouter(Season)
