import React from 'react'
import cc from 'classcat'

export default class TeamCard extends React.Component {
  render () {
    const { team } = this.props
    return !team ? '' : (
      <div className="team-card-wrapper">
        <div
          onClick={this.props.onChange}
          className={cc({
            'team-card': true,
            'picked': this.props.isPicked,
            'game-has-pick': this.props.isGameHasPick,
          })}
        >
          <h3>
            <span className="eyebrow">
              { team.cityState.replace(team.nick, '') }
            </span>
            <span className="h3-content">
              { team.nick }
            </span>
          </h3>
          <img
            className="team-card__image"
            src={
              /*'https://i.nflcdn.com/static/site/7.5/img/logos/svg/teams-matte-mascot/' +*/
              'https://res.cloudinary.com/kof/image/upload/nfl/team-logos/' +
              (/pro bowl/i.test(team.nick) ? team.nick[0].toLowerCase() + 'pr' : team.nick.toLowerCase()) + '.svg'
            }
            alt={team.nick + ' logo'}
          />
        </div>
        {this.props.usersWhoPickedThisTeam.map((_user, i) =>
          <div
            key={_user.uid || i}
            className={cc({
              'team-card__picked-by-user': true,
              'team-card__picked-by-user--current-user': _user && _user.isCurrentUser,
              'team-card__picked-by-user--not-locked': _user && !_user.isLocked,
            })}
          >
            { ((_user || {}).displayName || 'Anonymous').split(' ')[0] }
          </div>
        )}
      </div>
    )
  }
}
