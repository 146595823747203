import React from 'react'
import { getSessionWeekUrl } from '../util/season'
import { withRouter } from 'react-router-dom'

// there can be an idea of a current season & week in real life
// there can be an idea of a user-selected season & week
// leagues/${leagueId}/users/${user.uid}/season

class Index extends React.Component {

  componentDidMount () {
    let url = getSessionWeekUrl()
    console.log('redirecting to', url)
    this.props.history.push(url)
  }

  render () {
    return "Loading..."
  }
}

export default withRouter(Index)
