import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import "../styles/styles.scss"
import { withRouter } from 'react-router-dom'
// import cc from 'classcat'

// for now, there's only one league
const leagueId = "-KzPdROlkcWZDUsd47av";


// there can be an idea of a current season & week in real life
// there can be an idea of a user-selected season & week
// leagues/${leagueId}/users/${user.uid}/season

class Profile extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      user: this.props.user,
    };
    this.addDbListener = this.addDbListener.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  getProfileRef () {
    return firebase.database().ref('leagues/' + leagueId + '/users/' + this.state.user.uid + '/profile')
  }

  handleLogout () {
    let _this = this
    this.state.ReactGA && this.state.ReactGA._event({
      category: 'auth',
      action: 'logout',
      label: '',
    })
    firebase.auth().signOut().then(() => {
      this.getProfileRef().off()
      _this.props.history.push('/')
    })
  }

  addDbListener () {
    this.getProfileRef().on('value', snap => {
      let profile = snap.val()
      this.setState({
        profile,
      })
    }, (error) => {
      console.error(error)
    })
  }

  componentWillUnmount () {
    // firebase.database().ref().off()
  }

  onChange (event) {
    let target = event.target
    let name = target.name
    let value = target.type === 'checkbox' ? target.checked : target.value
    this.state.ReactGA && this.state.ReactGA._event({
      category: 'profile',
      action: 'toggleHideScores',
      label: [this.props.query.year, this.props.query.seasonType, this.props.query.week].join('/'),
    })
    this.getProfileRef().child(name).set(value)
    // the dblistener will pick up the change coming from the db and update the state.
  }

  render () {
    // let _this = this;
    let profile = this.state.profile || {}

    return <div className={'landing'}>
      <br/>
      <br/>
      <label className="switch">
        <span className={'switcher'}>
          <input
            className="switch__input"
            type="checkbox"
            name="hideScores"
            checked={!!profile.hideScores}
            onChange={this.onChange}
          />
          <span className="switch__slider"/>
        </span>
        <span className={'switch__label'}>
          Hide scores
        </span>
      </label>
      <br/>
      <br/>
      <button onClick={this.handleLogout}>Log out</button>
      <br/>
      <br/>
    </div>
  }

}
export default withRouter(Profile)
