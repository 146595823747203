import React from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import './App.scss';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import clientCredentials from './credentials/client'
import { getSessionWeekUrl } from './util/season'
import Logo from './components/Logo'
import Season from './routes/Season'
import Index from './routes/Index'
import Profile from './routes/Profile'

export default class App extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      user: null,
    }
    this.onLoginClick = this.onLoginClick.bind(this)
    if (!firebase.apps.length) {
      // console.log('LAYOUT :: initializing firebase app')
      firebase.initializeApp(clientCredentials)
    }
  }

  componentDidMount () {
    // if (this.props.user) return;
    let _this = this
    firebase.auth().onAuthStateChanged(user => {
      console.log('LAYOUT :: firebase.auth().onAuthStateChanged >> user:', user)
      _this.setState({user})
    })
  }

  onLoginClick () {
    // this.props.ReactGA && this.props.ReactGA._event({
    //   category: 'auth',
    //   action: 'login',
    //   label: ''
    // })
    firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
    // login success will be handled by auth state changed listener, added in componentDidMount
  }

  render() {
    let url = getSessionWeekUrl()
    return (
      <BrowserRouter>
        <div className="page season">
          <header className={'page-header'}>
            <nav>
              <Link to={url}>
                <span>
                  <Logo/> <u>King of Football</u>
                </span>
              </Link>
              {
                this.state.user
                  ? <Link to='/profile'><span>Profile</span></Link>
                  : <button onClick={this.onLoginClick}>Log in</button>
              }
            </nav>
          </header>
          <Switch>
            <Route
              path="/season/:year([2][0-9]{3})/:seasonType(PRE|REG|POST)/:week([1-9]|1[0-7])"
              render={() => <Season user={this.state.user}/>}
            />
            <Route
              path="/profile"
              render={() => <Profile user={this.state.user}/>}
            />
            <Route
              component={Index}
              exact
              path="/"
            />
          </Switch>
          <footer className={'page-footer'}>
            King of Football
          </footer>
        </div>
      </BrowserRouter>
    )
  }
}
